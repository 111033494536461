<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    id="modal-valida-duplicado"
    centered
    size="lg"
    hide-footer
    cancel-variant="default"
    @hide="clearDados"
    @show="loadImpressoras"
  >
    <template v-if="isLoad">
      <template slot="modal-header">
        <h4 class="modal-title">Validar objeto duplicado</h4>
        <a
          class="btn btn-danger btn-close"
          @click="$bvModal.hide('modal-valida-duplicado')"
        ></a>
      </template>
      <form @submit.prevent="onFormSubmit">
        <div class="row">
          <div class="col-6 mb-2">
            <label class="form-label">Nome arquivo:</label>
            <input
              class="form-control"
              required
              readonly
              type="text"
              :value="duplicada.ordem_servico.nome_arquivo.slice(0, -4)"
            />
          </div>

          <div class="col-3 mb-2">
            <label class="form-label">Caixa:</label>
            <input
              class="form-control"
              required
              readonly
              type="text"
              :value="duplicada.caixa"
            />
          </div>
          <div class="col-3 mb-2">
            <label class="form-label">Sequência Objeto:</label>
            <input
              class="form-control"
              required
              readonly
              type="text"
              :value="duplicada.sequencia_objeto"
            />
          </div>

          <div class="col-12 mb-2">
            <label class="form-label">Código de barras:</label>
            <input
              ref="codigoBarrasInput"
              class="form-control"
              @keydown.enter="onFormSubmit"
              :disabled="saving"
              min="12"
              max="12"
              type="number"
              v-model="cod_barra"
            />
          </div>

          <!-- <div class="w-100 d-flex justify-content-between mt-3">
            <a
              class="me-2 rounded btn btn-danger btn-sm"
              @click="$bvModal.hide('modal-valida-duplicado')"
            >
              <i class="fa fa-close me-2" />Fechar
            </a>
            <button
              :disabled="saving"
              type="submit"
              class="d-flex align-items-center me-2 rounded btn btn-warning btn-sm"
            >
              <b-spinner v-if="saving" small class="me-2" />
              <i class="fa fa-save me-2" />Validar
            </button>
          </div> -->
        </div>
      </form>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ModalValidaDuplicado',
  props: ['duplicada', 'loadItens', 'id', 'caixa'],
  data() {
    return {
      isLoad: true,
      saving: false,
      cod_barra: '',
      impressora: '',
      arquivo: '',
      inicio: '',
      fim: '',
      impressorasOptions: []
    }
  },
  methods: {
    clearDados() {
      this.nome = ''
      this.cod_barra = ''
    },
    async loadImpressoras() {
      this.isLoad = true
      // await this.loadItens
      setTimeout(() => {
        this.loadSetFocus()
      }, 100)
    },

    async loadSetFocus() {
      this.$nextTick(() => {
        this.$refs.codigoBarrasInput.focus()
      })
    },
    async onFormSubmit() {
      console.log(this.cod_barra.length)
      if (this.cod_barra.length !== 12) {
        this.clearDados()
        this.$notify({
          group: 'top-center',
          title: 'Erro ao validar objeto.',
          text: 'Código de barras inválido',
          type: 'error'
        })
        return
      }
      this.saving = true
      let data = {
        tipo: 'DUPLICADO',
        codigo_barras: this.cod_barra,
        ordem_servico: parseInt(this.id),
        caixa: parseInt(this.caixa)
      }

      let url = 'producao/rastreabilidade'
      let method = 'put'

      await axios({ method, url, data })
        .then(res => {
          return res.data
        })
        .then(res => {
          this.$notify({
            group: 'top-center',
            title: `Objeto validado com sucesso!`,
            text: res.mensagem,
            type: 'success'
          })
          this.clearDados()
          this.fecharModal()
          this.loadItens()
        })
        .catch(error => {
          this.$notify({
            group: 'top-center',
            title: 'Erro ao validar objeto.',
            text: error.response.data.mensagem,
            type: 'error'
          })
        })
      this.saving = false
    },
    fecharModal() {
      this.$bvModal.hide('modal-valida-duplicado')
    }
  }
}
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
