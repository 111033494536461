<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    id="modal-valida-nao-lidos"
    centered
    size="lg"
    hide-footer
    cancel-variant="default"
    @hide="clearDados"
    @show="loadImpressoras"
  >
    <template v-if="isLoad">
      <template slot="modal-header">
        <h4 class="modal-title">Validar não lidos.</h4>
        <a
          class="btn btn-danger btn-close"
          @click="$bvModal.hide('modal-valida-nao-lidos')"
        ></a>
      </template>
      <div class="col-12 mb-2">
        <label class="form-label">Pesquisar:</label>
        <input class="form-control" type="text" v-model="filter" />
      </div>
      <b-table
        sticky-header
        :filter="filter"
        :fields="fields"
        :busy="isBusy"
        :dark="true"
        :items="items"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template #cell(os)="data">
          {{ data.item.ordem_servico.id.toString().padStart(6, '0') }}
        </template>
        <template #cell(nome_arquivo)="data">
          {{ data.item.ordem_servico.nome_arquivo.slice(0, -4) }}
        </template>
        <template #cell(sequencia_objeto)="data">
          {{ data.item.sequencia_objeto.toString().padStart(6, '0') }}
        </template>
      </b-table>
      <h3 class="font-size-total">
        Total de objetos não lidos: <strong>{{ items.length }}</strong>
      </h3>
      <form @submit.prevent="onFormSubmit">
        <div class="col-12 mb-2">
          <label class="form-label font-label-cod"
            >Digite o código de barras:</label
          >
          <input
            ref="codigoBarrasInput"
            @keydown.enter="onFormSubmit"
            :disabled="saving"
            class="form-control font-label-cod"
            min="12"
            max="12"
            type="number"
            v-model="codigo_barras"
          />
        </div>
      </form>
    </template>
  </b-modal>
</template>
<script>
import axios from 'axios'

export default {
  name: 'ModalValidaNaoLidos',
  props: ['loadItens', 'id', 'caixa'],
  data() {
    return {
      isLoad: true,
      saving: false,
      isBusy: false,
      codigo_barras: '',
      filter: '',
      fields: [
        { key: 'os', label: 'Os' },
        { key: 'nome_arquivo', label: 'Arquivo' },
        { key: 'caixa', label: 'Caixa' },
        { key: 'sequencia_objeto', label: 'Sequência' }
      ],
      items: []
    }
  },
  methods: {
    clearDados() {},
    async loadImpressoras() {
      this.isLoad = true
      this.isBusy = true

      await axios
        .get(this.apiUrl)
        .then(res => {
          return res.data
        })
        .then(res => {
          this.items = res.dados.data
          this.isBusy = false

          this.$nextTick(() => {
            this.$refs.codigoBarrasInput.focus()
          })
        })
    },
    async onFormSubmit() {
      if (this.codigo_barras.length !== 12) {
        this.$notify({
          group: 'top-center',
          title: 'Erro ao validar objeto.',
          text: 'Código de barras inválido',
          type: 'error'
        })
        this.codigo_barras = ''
        return
      }
      this.saving = true
      let data = {
        tipo: 'EM_PRODUCAO',
        codigo_barras: this.codigo_barras,
        ordem_servico: parseInt(this.id),
        caixa: parseInt(this.caixa)
      }

      let url = 'producao/rastreabilidade?per_page=100000'
      let method = 'put'

      await axios({ method, url, data })
        .then(res => {
          return res.data
        })
        .then(res => {
          this.$notify({
            group: 'top-center',
            title: `Objeto validado com sucesso!`,
            text: res.mensagem,
            type: 'success'
          })
          this.codigo_barras = ''
          this.saving = false
          this.loadImpressoras()
        })
        .catch(error => {
          this.$notify({
            group: 'top-center',
            title: 'Erro ao validar objeto.',
            text: error.response.data.mensagem,
            type: 'error'
          })
          this.codigo_barras = ''
        })
      this.saving = false
    },
    fecharModal() {
      this.$bvModal.hide('modal-valida-nao-lidos')
    }
  },
  computed: {
    apiUrl() {
      return `producao/rastreabilidade/listar-producao?per_page=100&page=1&ordem_servico=${this.id}&caixa=${this.caixa}&status=EM_PRODUCAO`
    }
  }
}
//mostrar paginas varias if multivias true
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.font-label-cod {
  font-size: 20px;
}

.font-size-total {
  font-size: 17px;
}
</style>
