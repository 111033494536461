<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>
      <li class="breadcrumb-item">
        <a href="/app/ordem-servico">Ordens de serviços</a>
      </li>
      <li class="breadcrumb-item">
        <a @click="goBack" style="cursor: pointer">Envelopamento</a>
      </li>
      <li class="breadcrumb-item">Objetos em produção</li>
    </ol>

    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header" @click="loadItens">Objetos em produção</h1>

    <template v-if="isLoad">
      <panel :noButton="true">
        <div
          slot="header"
          class="w-100 d-flex align-items-center justify-content-between"
        >
          <span>Listagem de objetos em produção </span>
        </div>
        <div class="d-flex justify-content-between">
          <select
            style="max-width: 180px"
            class="form-select"
            required
            v-model="status"
          >
            <option
              v-for="(item, i) in statusOptions"
              :key="i"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </select>
          <div class="d-flex justify-content-between">
            <button
              @click="gerarRefaz"
              class="btn btn-warning"
              style="width: 125px"
            >
              Gerar Refaz
            </button>
            <button
              @click="opemModalNaoLidos"
              class="btn btn-primary"
              style="width: 125px; margin-left: 10px"
            >
              Validar não lidos
            </button>
          </div>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :lineNumbers="false"
          :styleClass="'vgt-table striped'"
          :search-options="{ enabled: true, placeholder: 'Pesquisar' }"
          :pagination-options="pageOptions"
        >
          <div slot="emptystate" class="w-100 text-center">
            Sem registros encontrados
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'os'">
              {{ props.row.ordem_servico_id.toString().padStart(6, '0') }}
            </span>
            <span v-if="props.column.field === 'nome_arquivo'">
              {{ props.row.ordem_servico.nome_arquivo.slice(0, -4) }}
            </span>
            <span v-if="props.column.field === 'sequencia_objeto'">
              {{ props.row.sequencia_objeto.toString().padStart(6, '0') }}
            </span>

            <span v-if="props.column.field === 'caixa'">
              {{ props.row.caixa.toString().padStart(3, '0') }}
            </span>
            <span v-if="props.column.field === 'em_producao'">
              {{ props.row.dt_producao | filterDate('DD/MM/yyyy HH:mm:ss') }}
            </span>
            <span v-if="props.column.field === 'envelopadora'">
              {{ props.row.envelopadora }}
            </span>
            <span v-if="props.column.field === 'acao'">
              <button
                v-if="props.row.botao"
                class="btn btn-success me-2"
                @click="acaoDuplicada(props.row)"
              >
                {{ props.row.botao }}
              </button>
            </span>
          </template>
        </vue-good-table>
      </panel>
    </template>
    <template v-else>
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </template>
    <!-- END page-header -->

    <modal-impressao v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
    <modal-reimpressao v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
    <modal-valida-duplicado
      v-if="duplicada"
      :loadItens="loadItens"
      :id="$route.params.id"
      :caixa="$route.params.caixa"
      :duplicada="duplicada"
    />
    <modal-valida-nao-lidos
      :id="$route.params.id"
      :caixa="$route.params.caixa"
      :loadItens="loadItens"
    />
  </div>
</template>

<script>
import axios from 'axios'
import ModalImpressao from './components/ModalImpressao.vue'
import ModalReimpressao from './components/ModalReimpressao.vue'
import ModalValidaDuplicado from './components/ModalValidaDuplicado.vue'
import ModalValidaNaoLidos from './components/ModalValidaNaoLidos.vue'

export default {
  components: {
    ModalImpressao,
    ModalReimpressao,
    ModalValidaDuplicado,
    ModalValidaNaoLidos
  },
  data() {
    return {
      isLoad: false,
      ordem: undefined,
      duplicada: undefined,
      status: '',
      statusOptions: [
        { label: 'Todos', value: '' },
        { label: 'Não lidos', value: 'EM_PRODUCAO' },
        { label: 'Duplicados', value: 'DUPLICADO' }
      ],
      columns: [
        {
          label: 'os',
          field: 'os',
          sortable: false
        },
        {
          label: 'Nome',
          field: 'nome_arquivo',
          sortable: false
        },
        {
          label: 'Caixa',
          field: 'caixa',
          sortable: false
        },
        {
          label: 'Sequência',
          field: 'sequencia_objeto',
          sortable: false
        },
        {
          label: 'Ações',
          field: 'acao',
          tdClass: 'text-center',
          sortable: false
        }
      ],
      rows: []
    }
  },
  methods: {
    goBack() {
      window.history.back()
    },
    acaoDuplicada(duplicada) {
      console.log(duplicada)
      this.duplicada = duplicada
      setTimeout(() => {
        this.$bvModal.show('modal-valida-duplicado')
      }, 200)
    },
    opemModalNaoLidos() {
      this.$bvModal.show('modal-valida-nao-lidos')
    },
    gerarRefaz() {
      console.log(this.rows)
      // Agora você tem acesso ao valor de nome_arquivo
      let Swal = this.$swal
      let data = {
        ordem_servico_id: this.rows[0].ordem_servico_id,
        caixa: this.rows[0].caixa
      }
      let url = 'producao/refaz-automatico'
      let method = 'post'
      Swal.fire({
        title: 'Geração de arquivo refaz',
        text: 'Tem certeza que deseja gerar o refaz de todos os objetos não lidos desta caixa? \n',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Gerar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios({ method, url, data })
            .then(res => {
              return res.data
            })
            .then(res => {
              return res
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Erro: ${error.response.data.mensagem}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title:
              'O arquivo foi gerado com sucesso e já está disponível para impressão!',
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
    },
    async loadItens() {
      this.isLoad = false
      await axios
        .get(this.apiUrl)
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.rows = res.data
          this.isLoad = true
        })
    }
  },
  computed: {
    apiUrl() {
      return `producao/rastreabilidade/listar-producao?per_page=10000&page=1&ordem_servico=${this.$route.params.id}&caixa=${this.$route.params.caixa}&status=${this.status}`
    },
    pageOptions() {
      return {
        enabled: true,
        mode: 'records',
        perPage: 20,
        position: 'bottom',
        perPageDropdown: [20, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próxima',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Linhas por página',
        ofLabel: 'de',
        pageLabel: 'página', // for 'pages' mode
        allLabel: 'Todos',
        infoFn: params => `Página atual ${params.firstRecordOnPage}`
      }
    }
  },
  mounted() {
    this.loadItens()
  },
  watch: {
    status() {
      this.loadItens()
    }
  }
}
</script>
